.amount {
  position: absolute;
  top: 17%;
  font-size: 1.6rem;
  margin-left: 5px;
}

.underline {
  background-image: linear-gradient(
    transparent 0%,
    transparent calc(50% - 9px),
    #dbca2e calc(50% - 9px),
    #dbca2e 100%
  );
  background-size: 50% 220%;
}

.Mui-checked > span.MuiIconButton-label > span {
  color: #1a4e55;
}

.MuiSwitch-colorSecondary.Mui-checked + span.MuiSwitch-track {
  background-color: #82c99b;
}

.searchCardMedia {
  width: 200px;
  height: 140px;
  cursor: pointer;
}

.searchObjectField {
  height: clamp(90%, 95%, 100%);
}

.searchLink {
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchLink a {
  text-decoration: none;
}

.searchLink a:hover {
  text-decoration: underline;
}

.courtSurface::first-letter {
  text-transform: capitalize;
}

.sortFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.sortFilterTitle {
  text-align: center;
  margin-top: 1rem;
}

@media (width < 800px) {
  .amount {
    top: 20%;
  }
}

@media (width < 550px) {
  .amount {
    width: 100px;
  }
}

@media (width < 450px) {
  .amount {
    width: 80px;
  }
}

@media (width < 420px) {
  .amount {
    top: 16%;
  }
}
