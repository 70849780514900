.map-container {
  height: 91.5vh;
}

.map-container-small {
  height: 41vh;
  width: 56vh;
}

.mapboxgl-ctrl-geocoder {
  width: clamp(15vw, 25vw, 150%) !important;
}

.mapboxgl-popup-content a {
  text-decoration: none;
}

.mapboxgl-popup-content a:hover {
  text-decoration: underline;
}

h3 {
  padding-top: 10px;
}

@media (width < 700px) {
  .map-container-small {
    width: 70%;
  }
}

@media (width < 600px) {
  .map-container-small {
    width: 80%;
  }
}

@media (width < 500px) {
  .map-container-small {
    width: 90%;
  }
}
