.basketballField {
  display: grid;
  grid-template-rows: 0.5fr 1fr 0.25fr;
  width: clamp(200px, 100%, 400px);
  height: clamp(200px, 100%, 450px);
}

.basketballField a {
  text-decoration: none;
}

.basketballField a:hover {
  text-decoration: underline;
}

.basketballField > div:first-of-type {
  position: relative;
}

.hoverPictureContainer {
  position: absolute;
  transform: translateX(150px);
  z-index: 1;
}

.hoverPicture {
  border-radius: 5px;
}

.cardMedia {
  height: 140px;
  cursor: pointer;
}

.listCourt {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 45ch), 1fr));
  gap: 3rem;
  margin: 2.5rem;
}

.listCourt.search > div {
  width: 95vw;
  height: 100vh !important;
  margin-top: 3rem;
}

.listCourt.search > div > div > div {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
}

.iconLink {
  width: 24px;
  color: #1a4e55;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.pagination button {
  color: #1a4e55;
}

.pagination button[aria-current="true"] {
  background-color: #82c99b;
}

.courtSurface::first-letter {
  text-transform: capitalize;
}

.searchForm {
  width: 100px;
}

.surfaceIcon {
  cursor: pointer;
}

.MuiTypography-colorError {
  color: #b7021a !important;
}

.childrenAlert {
  position: absolute;
  right: 2%;
  top: 71%;
}

@media (width < 1300px) {
  .MuiCardActions-spacing > :not(:first-child) {
    margin-left: -10px !important;
  }
}

@media (width < 1250px) {
  .listCourt.search > div > div > div {
    grid-template-columns: repeat(4, 1fr);
  }

  .iconLinks.MuiCardActions-spacing > :not(:first-child) {
    margin-left: -20px !important;
  }

  .iconLinks.MuiCardActions-spacing > :first-child {
    margin-left: -10px !important;
  }
}

@media (width < 1050px) {
  .listCourt.search > div > div > div {
    grid-template-columns: repeat(3, 1fr);
  }

  .MuiCardActions-spacing > :first-child {
    margin-left: -10px !important;
  }
}

@media (width < 900px) {
  .sortFilter.sort {
    margin-top: 40px;
  }
}

@media (width < 850px) {
  .listCourt.search > div > div > div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width < 800px) {
  .sortFilter.sort {
    margin-top: 0;
  }
}

@media (width < 650px) {
  .listCourt.search > div > div > div {
    grid-template-columns: repeat(1, 1fr);
  }

  .sortFilter.sort {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 80px;
  }
}

@media (width < 450px) {
  .listCourt {
    margin: 10px 20px 20px;
  }

  .iconLinks {
    margin-left: -10px;
  }

  .MuiPagination-root li {
    max-width: 35px;
  }

  .sortFilter.sort {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 50px;
  }
}
